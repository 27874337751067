import logoDark from "../../assets/images/shared/desktop/logo-dark.png";
import logoLight from "../../assets/images/shared/desktop/logo-light.png";
import twitter from "../../assets/images/shared/desktop/icon-twitter.svg";

export const menuData = {
  header: {
    headerLogo: logoDark,
    headerLogoAlt: "lia.mk web agency",
    label: "visit homepage",
  },
  footer: {
    footerLogo: logoLight,
    footerLogoAlt: "LIA.MK",
    label: "go back to homepage",
  },
  homeLink: "/",
  address: {
    office: "LIA.MK (Carlisle Office)",
    street: "16 Dalegarth Avenue",
    city: "Carlisle, Cumbria CA1 3LT",
  },
  contact: {
    title: "Contact Us (Carlisle Office)",
    phone: "+44 793 5677 437",
    phoneLink: "tel:+44 793 5677 437",
    phoneLabel: "call us via our official number",
    email: "contact@lia.mk",
    emailLink: "mailto:contact@lia.mk",
    emailLabel: "send us an email"
  },
  social: [
    {
      label: "follow us on twitter",
      icon: twitter,
      link: "https://twitter.com/liamkenyon",
    }
  ],
  menu: [
    {
      title: "our company",
      link: "/about",
      label: "visit about page",
    },
    {
      title: "locations",
      link: "/locations",
      label: "visit locations page",
    },
    {
      title: "contact",
      link: "/contact",
      label: "visit contact page",
    },
  ],
};
