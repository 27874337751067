import CanadaImage from "../../../assets/images/shared/desktop/illustration-canada.svg";
import AustraliaImage from "../../../assets/images/shared/desktop/illustration-australia.svg";
import UkImage from "../../../assets/images/shared/desktop/illustration-united-kingdom.svg";

export const locationListData = [
  {
    country: "United Kingdom",
    image: UkImage,
    label: "see our United Kingdom contact information",
    anchorLink: "locations#united-kingdom",
  },
];

export const locationData = {
  uk: {
    id: "united-kingdom",
    country: "United Kingdom",
    image: UkImage,
    anchorLink: "",
    address: {
      office: "LIA.MK Carlisle Office",
      street: "16 Dalegarth Avenue",
      state: "Cumbria CA1 3LT",
    },
    contact: {
      header: "Contact",
      phone: "079356 77437",
      mail: "contact@lia.mk",
    },
    map: {
      src: "https://maps.google.com/maps?q=16%20dalegarth%20avenue&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
  },
};
